import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {Component, inject} from '@angular/core';
import { $localize } from '@angular/localize/init';

import { TfAutoUnsubscribeComponent } from "../auto-unsubscribe-component";
import { ToastController } from '@ionic/angular';
import { TfAccessTokenHttpService } from '../services/acess-token-http.service';
import { ROUTE_PATHS} from '../app.constants';
import { FORM_CONTROLS } from "./login-page.constants";
import { HttpErrorResponse } from "@angular/common/http";
import { ActivatedRoute } from "@angular/router";
import {firstValueFrom} from "rxjs";
import { TfAccessTokenService } from "../services/access-token.service";

@Component({
  selector: 'tf-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
  host: { class: 'login-page' },
})
export class TfLoginPageComponent extends TfAutoUnsubscribeComponent {
  public readonly FORGOT_PASSWORD_PATH: string = `/${ROUTE_PATHS.FORGOT_PASSWORD}`;
  public readonly CODE_CHALLENGE_NAME: string = 'code';

  private readonly accessTokenService = inject(TfAccessTokenService);
  private readonly accessTokenHttpService = inject(TfAccessTokenHttpService);
  private readonly formBuilder = inject(FormBuilder);
  private readonly toastController = inject(ToastController);
  private readonly route = inject(ActivatedRoute);

  public readonly form: FormGroup = this.formBuilder.group({
    [FORM_CONTROLS.EMAIL]: ['', [Validators.required, Validators.email]],
    [FORM_CONTROLS.PASSWORD]: ['', [Validators.required]],
    [FORM_CONTROLS.REMEMBER_ME]: true,
  });

  public isLoading = false;

  public constructor() {
    super();
  }

  public async login(): Promise<void> {
    try {
      this.isLoading = true;

      this.validateFormErrors();
      await this.createUserSession();
    } catch (error: any) {

      this.isLoading = false;
      this.toastController.create({message: error.message}).then(async toast => await toast.present());
    }
  }

  private validateFormErrors(): void {
    const emailErrors = this.form.controls[FORM_CONTROLS.EMAIL].errors;

    if (emailErrors) {
      this.form.setErrors(emailErrors);
    }

    if (!this.form.errors) {
      return;
    }

    const errorKeys = Object.keys(this.form.errors);

    throw new Error(errorKeys[0]);
  }

  private async createUserSession(): Promise<void> {
    try {
      const formData = this.form.value;
      const rememberMe = this.form.value.rememberMe;

      await firstValueFrom(this.accessTokenHttpService.create$(formData, rememberMe));
      const response: { status: string; redirect_uri: string } = await firstValueFrom(
        this.accessTokenHttpService.getAuthCode$(this.route.snapshot.queryParams[this.CODE_CHALLENGE_NAME])
      );

      const message = `You are now redirecting to the app: ${response.redirect_uri}`;
      this.toastController.create({ message }).then(async (toast) => await toast.present());

      window.location.href = response.redirect_uri;
    } catch (error) {
      if (error instanceof HttpErrorResponse) {

        const message = `Invalid login attempt: ${error.error.message}`;

        this.form.controls[FORM_CONTROLS.EMAIL].setErrors({ invalidAttempt: true });
        this.form.controls[FORM_CONTROLS.PASSWORD].setErrors({ invalidAttempt: true });

        // @todo update this part to a single catch state
        this.isLoading = false;

        this.toastController.create({ message }).then(async (toast) => await toast.present());
      }
    }
  }

  protected readonly window = window;
}
