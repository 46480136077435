import { ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';
import { inject } from '@angular/core';
import { TfAccessTokenService } from '../services/access-token.service';
import { TfAccessTokenHttpService } from '../services/acess-token-http.service';
import { ToastController } from '@ionic/angular';
import { Router } from '@angular/router';
import { firstValueFrom } from "rxjs";

export const canLogout: CanActivateFn = async (
  route: ActivatedRouteSnapshot
): Promise<boolean> => {
  const accessTokenService = inject(TfAccessTokenService);
  const accessTokenHttpService = inject(TfAccessTokenHttpService);
  const toastController = inject(ToastController);
  const router = inject(Router);
  const CODE_CHALLENGE_NAME = 'code';

  if (!accessTokenService.exist) {

    await router.navigateByUrl('/');
  } else {
    const message = 'Logout process started';
    await presentToast(toastController, message);

    try {
      await firstValueFrom(accessTokenHttpService.delete$());
      accessTokenService.destroy();

      await router.navigateByUrl(`/?code=${route.queryParams[CODE_CHALLENGE_NAME]}`);
    } catch (e: any) {
      // @todo check BE response types

      accessTokenService.destroy();
      await router.navigateByUrl(`/?code=${route.queryParams[CODE_CHALLENGE_NAME]}`);
    }
  }

  return false;
};

async function presentToast(
  toastController: ToastController,
  message: string
) {
  const toast = await toastController.create({ message });
  await toast.present();
}
